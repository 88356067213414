interface IError {
    errorMessage?: string;
    memberNames?: string[];
}

export const mapServerValidationMessages = (errors: IError[]|string): Record<string, any> => {
    if (!errors?.length || (typeof errors === 'string')) {
        return;
    }
    const validationErrors = {};
    errors?.forEach((error: {errorMessage: string, memberNames: string[]}) => {
        error?.memberNames?.forEach(memberName => {
            validationErrors[lowercaseFirstLetter(memberName)] = error?.errorMessage;
        });
    });
    return validationErrors;
}

const lowercaseFirstLetter = (stringValue: string) => {
    return stringValue?.split('.').map(str => str[0].toLowerCase() + str.slice(1)).join('.');
}
